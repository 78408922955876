import { makeStyles } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { Redirect, Switch, useHistory } from 'react-router-dom';
import {
  Amplify, Auth, Hub, API,
} from 'aws-amplify';
import PublicRoute from './components/PublicRoute';
import PrivateRoute from './components/PrivateRoute';
import Login from './pages/Login';
import Jobs from './pages/Jobs';
import Addjob from './pages/AddJob';
import QrRedirects from './pages/QrRedirects';
import Header from './components/Header';
import config from './config';
import './index.css';
import AddQrRedirect from './pages/AddQrRedirect';

const useStyles = makeStyles(() => ({
  app: {
    height: '100%',
    width: '100%',
  },
}));

const configureAmplify = () => {
  Amplify.configure({
    Auth: {
      mandatorySignIn: true,
      region: config.cognito.REGION,
      userPoolId: config.cognito.USER_POOL_ID,
      userPoolWebClientId: config.cognito.APP_CLIENT_ID,
    },
    Storage: {
      AWSS3: {
        bucket: config.storage.bucket,
        region: config.storage.region,
      },
    },
  });
  API.configure({
    endpoints: [
      {
        name: 'v1',
        endpoint: config.apiGateway.URL,
        region: config.apiGateway.REGION,
        custom_header: async () => ({ Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}` }),
      },
    ],
  });
};

const App = () => {
  const classes = useStyles();
  const [email, setEmail] = useState(null);
  const [isQrUser, setIsQrUser] = useState(null);
  const history = useHistory();
  const onAmplifyEvent = (data) => {
    switch (data.payload.event) {
      case 'signOut':
        setEmail(null);
        break;
      case 'signIn':
        setEmail(
          data.payload.data.attributes
            ? data.payload.data.attributes.email
            : data.payload.data.challengeParam.userAttributes.email,
        );
        setIsQrUser(data.payload.data.attributes['custom:role'] === 'qr');
        break;
      case 'configured':
        Auth.currentAuthenticatedUser()
          .then((user) => {
            const isQr = user.attributes['custom:role'] === 'qr';
            setIsQrUser(isQr);
            setEmail(user.attributes.email);
          })
          .catch(() => {
            setEmail(null);
          });
        break;
      default:
        console.log(data.payload.event);
    }
  };

  useEffect(() => {
    Hub.listen('auth', onAmplifyEvent);
    configureAmplify();
    return () => {
      Hub.remove('auth', onAmplifyEvent);
    };
  }, []);

  useEffect(() => {
    if (isQrUser) {
      history.push('/qr-redirects');
    } else {
      history.push('/jobs');
    }
  }, [isQrUser, history]);

  return (
    <div className={classes.app}>
      {email && <Header userName={email.split('@')[0]} isQrUser={isQrUser} />}
      <Switch>
        <PublicRoute authed={!!email} path="/login" component={Login} />
        <PrivateRoute authed={!!email} exact path="/jobs" component={Jobs} />
        <PrivateRoute authed={!!email} exact path="/add-new-job" component={Addjob} />
        <PrivateRoute authed={!!email} path="/edit-job/:id" component={Addjob} />
        <PrivateRoute authed={!!email} exact path="/qr-redirects" component={QrRedirects} />
        <PrivateRoute authed={!!email} path="/edit-qr-redirect/:id" component={AddQrRedirect} />
        <PrivateRoute authed={!!email} path="/add-new-qr-redirect" component={AddQrRedirect} />

        <Redirect exact from="/" to={isQrUser ? '/qr-redirects' : '/jobs'} />
      </Switch>
    </div>
  );
};

export default App;
